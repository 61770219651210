import { createContext, useContext, useState } from "react"
import { MODES } from "../components/atoms/select/CustomerOrFundSelect"

export const MaturityContext = createContext({
  assetName: null,
  setAssetName: () => {},
  customerOrFund: null,
  setCustomerOrFund: () => {},
  mode: null,
  setMode: () => {},
})

export const MaturityProvider = ({ children }) => {
  const [assetName, setAssetName] = useState(null)
  const [customerOrFund, setCustomerOrFund] = useState(null)
  const [mode, setMode] = useState(MODES.CUSTOMER)
  const [showOnlyNextMaturities, setShowOnlyNextMaturities] = useState(true)

  return (
    <MaturityContext.Provider
      value={{ assetName, setAssetName, customerOrFund, setCustomerOrFund, mode, setMode, showOnlyNextMaturities, setShowOnlyNextMaturities }}
    >
      {children}
    </MaturityContext.Provider>
  )
}

export const useMaturityContext = () => useContext(MaturityContext)
