import { Input } from "antd"
import { useEffect, useState } from "react"
import { colors } from "../../../utils/styles"

export const EditableLabel = ({ value, placeholder, onSubmit, width = 200, readOnly = false }) => {
  const [editing, setEditing] = useState(false)
  const [internalValue, setInternalValue] = useState(value || "")
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (!editing) setHover(false)
  }, [editing])

  const handleClickLabel = () => {
    setEditing(true)
  }

  const handleChange = (e) => {
    const newValue = e.target.value
    setInternalValue(newValue)
    if (!newValue) {
      onSubmit("")
      setEditing(false)
    }
  }

  const handleBlur = () => {
    if (internalValue !== value) {
      onSubmit(internalValue)
    }
    setEditing(false)
  }

  const handlePressEnter = () => {
    if (internalValue !== value) {
      onSubmit(internalValue)
    }
    setEditing(false)
  }

  return (
    <>
      {!editing || readOnly ? (
        <span
          onMouseEnter={!readOnly ? () => setHover(true) : undefined}
          onMouseLeave={!readOnly ? () => setHover(false) : undefined}
          style={{
            cursor: readOnly ? "default" : "pointer",
            minWidth: width,
            border: hover && !readOnly ? `1px solid ${colors.darkGray}` : "none",
            padding: hover ? "4px 8px" : "inherit",
            borderRadius: hover ? "6px" : "inherit",
            boxSizing: "border-box",
          }}
          onClick={readOnly ? undefined : handleClickLabel}
        >
          {readOnly ? internalValue : internalValue || <span style={{ color: "#aaa" }}>{placeholder || "Clique para editar"}</span>}
        </span>
      ) : (
        <Input style={{ width }} autoFocus value={internalValue} onChange={handleChange} onBlur={handleBlur} onPressEnter={handlePressEnter} />
      )}
    </>
  )
}
