import { filesQuantFormats } from "../../../utils/utils"
import { StaticFileViewer } from "../../molecules/viewers/StaticFileViewer"

export const Risk = () => {
  const files = [
    {
      title: "Benchmark Offshore",
      path: "risco/benchmark-offshore",
      folder: "risk/benchmark-offshore-risk-management",
      format: filesQuantFormats.PDF,
    },
    {
      title: "Benchmark Onshore",
      path: "risco/benchmark-onshore",
      folder: "risk/benchmark-onshore-risk-management",
      format: filesQuantFormats.PDF,
    },
    {
      title: "TERA Funds",
      path: "risco/tera-funds",
      folder: "risk/tera-funds-risk-management",
      format: filesQuantFormats.PDF,
    },
  ]

  return <StaticFileViewer files={files} />
}
