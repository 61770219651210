import { Flex } from "antd"
import JoditEditor from "jodit-react"
import { useMemo, useRef } from "react"

export const TextRich = ({ value = "", setValue = () => {}, placeholder = "", height = 200, width = 1150 }) => {
  const ref = useRef(null)

  const config = useMemo(
    () => ({
      toolbarAdaptive: false,
      readonly: false,
      placeholder,
      buttons: ["ul", "ol", "|", "bold", "italic", "underline", "brush", "|", "link", "unlink", "|", "table"],
      statusbar: false,
      height,
      minWidth: width,
    }),
    [value, placeholder, height, width],
  )

  return (
    <Flex style={{ width: "100%" }}>
      <JoditEditor ref={ref} value={value} config={config} onBlur={(content) => setValue && setValue(content)} />
    </Flex>
  )
}
